import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FaceIcon from '@mui/icons-material/Face';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import './App.css';

var apiurl = "fail"
switch(process.env.NODE_ENV) {
  case 'production':
    apiurl = 'https://presscapeditorapi.kattiskeramik.se/';
    break;
  case 'development':
  default:
    apiurl = 'https://presscapeditorapi.kattiskeramik.se/';
//    apiurl = 'https://presscapapi-4sumh3ynja-uc.a.run.app';
//    apiurl = 'http://localhost:5000';
}

const drawAreaX = 500
const drawAreaY = 500

class Controlls extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mirrormode: true,
      zoomvalue: 1.0,
      username: "",
    };
  }


  save() {
    this.props.saveCallback(this.state.username).then(() => this.props.refreshCallback(this.state.username))
  };

  new() {
    this.props.newCallback(this.state.mirrormode)
  };

  copy() {
    this.props.copyCallback()
  };

  split() {
    this.props.splitCallback()
  };

  zoom(val) {
    this.setState({zoomvalue: val})
    this.props.zoomCallback(val)
  }


  zoomdone(val) {
    this.setState({zoomvalue: 1.0})
    this.props.zoomDoneCallback(val)
  }

  usernameupdate(val) {
    this.setState({username: val})
  }

  generate() {
    this.props.genCallback(this.state.username).then(() => this.props.refreshCallback(this.state.username))
  };

  refresh() {
    this.props.refreshCallback(this.state.username)
  };

  delete() {
    this.props.delCallback(this.state.username).then(() => this.props.refreshCallback(this.state.username))
  };

  mirrorChange = changeEvent => {
    console.log("lets change")


  };

  render() {

    const marks = [
      {
        value: 4,
        label: '4',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 50,
        label: '50',
      },
    ];    
    const zoommarks = [
      {
        value: 0.1,
        label: '10%',
      },
      {
        value: 1,
        label: '100%',
      },
      {
        value: 3,
        label: '300%',
      },
    ];

    return (<div>
          <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '16ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField value={this.state.username} onChange={(e) => this.usernameupdate(e.target.value)} id="standard-basic" label="Name" variant="standard" />
    </Box>
    <div><Button variant="contained" onClick={() => this.new()}>New</Button>
                 <Button variant="contained" onClick={() => this.copy()}>copy</Button>
                 <Button variant="contained" onClick={() => this.split()}>split</Button></div>
                 <Button variant="contained" onClick={() => this.save()}>SAVE</Button>
                 <Button variant="contained" onClick={() => this.generate()}>GENERATE</Button>
                 <Button variant="contained" onClick={() => this.refresh()}>REFRESH</Button>
                 <Button variant="contained" disabled={this.state.username.split("+").length < 2} onClick={() => this.delete()}>Delete</Button>
                 <Slider defaultValue={10} onChange={(e, val) => this.props.sliderCallback(val)} min={4} max={50} aria-label="Custom marks" valueLabelDisplay="auto" marks={marks} step={2}/>
                 <Slider value={this.state.zoomvalue} defaultValue={1.0} onChangeCommitted={(e, val) => this.zoomdone(val)}  onChange={(e, val) => this.zoom(val)} min={0.1} max={3} aria-label="Custom marks" valueLabelDisplay="auto" marks={zoommarks} step={0.01}/>
                 
                 <FormGroup>
      <FormControlLabel control={<Checkbox onChange={(e, val) => this.setState(prevState => ({mirrormode: val}))} defaultChecked />} label="Mirrored" />
    </FormGroup>

                 </div>)
                }  
}


function addAfter(array, index, newItem) {
  return [
      ...array.slice(0, index),
      newItem,
      ...array.slice(index)
  ];
}



class Bezier extends React.PureComponent {
  constructor(props) {
    super(props);

    let mirrormode = true;
    var cp = []
    var ep = []
    var id = -1;
    if (typeof props.path !== 'undefined') {
      var book = JSON.parse(JSON.parse(props.path))
      id = this.props.id;
      ep = book.endPoints;
      cp = book.controlPoints;
    } else {
      let ret = this.newBase(4, mirrormode)
      id = ret.id
      cp = ret.cp
      ep = ret.ep
    }

    this.state = {
      // These are our 3 Bézier points, stored in state.
      controlPoints: cp,
      endPoints: ep,
      mirrormode: mirrormode,
      steps: 4,
      zoom: 1.0,
      id: id,
      // We keep track of which point is currently being
      // dragged. By default, no point is.
      // we also keep track which id we are using
      draggingPointId: null,
      draggingPointIdArray: 0,
      selectedPointId: null,
    };
  }


  sampleCoords(p, endPoints) {
    try {
      return {x: (p.linked === -1 ? p.x : drawAreaX - endPoints[p.linked].x)*this.state.zoom-drawAreaX/2*(this.state.zoom-1.0),
              y: (p.linked === -1 ? p.y : endPoints[p.linked].y)*this.state.zoom-drawAreaY/2*(this.state.zoom-1.0),
              linked: p.linked}
    } catch (error) {
      return {x: 0, y:0, linkes: false}
    }
  };
  
  cpLinked(endPoints, index) {
    return endPoints[(index-1+endPoints.length)%endPoints.length].linked !== -1;
  }
  
  
  // when we sample a control point its position may be modified if it is controlling curves between endpoints that are linked
  // having both linked means a mirroring similar to an endpoint
  // having one linked means we are locked to the vertical axis
  // endpoints will be a at index and index-1
  sampleCP(controlPoints, endPoints, index) {
    try {


      const linked = (this.cpLinked(endPoints, index) ? 1 : 0) + (this.cpLinked(endPoints,index+1) ? 1 : 0)

      if (linked === 0) {
        return {x: this.state.zoom*controlPoints[index].x-drawAreaX/2*(this.state.zoom-1.0), y: this.state.zoom*controlPoints[index].y-drawAreaY/2*(this.state.zoom-1.0)};
      } else if (linked === 2) {
        return {x: (drawAreaX - controlPoints[endPoints[(index-1+endPoints.length)%endPoints.length].linked].x)*this.state.zoom-drawAreaX/2*(this.state.zoom-1.0),
          y: (controlPoints[endPoints[(index-1+endPoints.length)%endPoints.length].linked].y)*this.state.zoom-drawAreaY/2*(this.state.zoom-1.0)}
      } else { // linked == 1
        return {x: (drawAreaX/2)*this.state.zoom-drawAreaX/2*(this.state.zoom-1.0), y: (controlPoints[index].y)*this.state.zoom-drawAreaY/2*(this.state.zoom-1.0)}
      }
    } catch (error) {
      return {x: 0, y:0, linkes: false}
    }
  };

  resampleCoords() {
    const {
      controlPoints,
	    endPoints
    } = this.state;

    var modep = []
    var modcp = []

    for (var i = 0; i < controlPoints.length; i++) {
      const epmodcoords = this.sampleCoords(endPoints[i], endPoints);
      const cpmodcoords = this.sampleCP(controlPoints, endPoints, i);
      modep.push(epmodcoords)
      modcp.push(cpmodcoords)
    }    

    this.setState({controlPoints:modcp})
    this.setState({endPoints:modep})
    return [modep, modcp]
  }

  getBezierString() {
    // use these points since state update may happen to late
    var updatedPoints = this.resampleCoords()

    const {
      controlPoints,
	    endPoints
    } = this.state;

    let book = {name: "dummy",
                endPoints: updatedPoints[0],
                  controlPoints: updatedPoints[1],
                  version: 1,
    };

    return JSON.stringify(book);

  }
  newBase(steps_passed, mirrormode) {
    var id = "-1"
    var cp = []
    var ep = []
    const steps = (steps_passed === -1) ? this.state.steps : steps_passed;
    
    const radi = drawAreaX/2-100
    for (let i = 0; i < steps; ++i) {
      cp.push({x: (drawAreaX/2)+(radi+5)*Math.sin(2*i*Math.PI/steps),   y: (drawAreaY/2)+(radi+5)*Math.cos(2*i*Math.PI/steps)})
      ep.push({x: (drawAreaX/2)+radi*Math.sin(2*(i+0.5)*Math.PI/steps), y: (drawAreaY/2)+radi*Math.cos(2*(i+0.5)*Math.PI/steps), linked:i < steps/2 || !mirrormode ? -1 : steps-i-1})
    }
    return {id:id, cp:cp, ep:ep};
  }

  new(mirrormode) {
    this.setState({mirrormode:mirrormode})

    var ret = this.newBase(this.state.steps, mirrormode);
    this.setState({controlPoints:ret.cp})
    this.setState({endPoints:ret.ep})
    this.setState({id:ret.id})
  }

  split() {
    if (this.state.selectedPointId !== null) {
      // insert new point
      const {
        controlPoints,
        endPoints
      } = this.state;

      // add the new poitn after the current point
      var newEndPoints = addAfter(endPoints, this.state.selectedPointId, {x: (endPoints[this.state.selectedPointId].x + 10.0),
                                                                          y: (endPoints[this.state.selectedPointId].y + 10.0), linked:-1} )
      var newControlPoints = addAfter(controlPoints, this.state.selectedPointId+1, {x: (endPoints[this.state.selectedPointId].x+5.0),
                                                                                  y: (endPoints[this.state.selectedPointId].y+5.0 )} )

      // update all links
      if (this.state.mirrormode) {
        for (var i = 0; i < newEndPoints.length; i = i + 1) {
          if (newEndPoints[i].linked > this.state.selectedPointId) {
            newEndPoints[i].linked = newEndPoints[i].linked + 1;
          }
        }  

        // add a linked point
        for (i = 0; i < newEndPoints.length; i = i + 1) {
          if (newEndPoints[i].linked === this.state.selectedPointId) {
            newEndPoints = addAfter(newEndPoints, i, {x:0, y:0,linked:this.state.selectedPointId+1})
            newControlPoints = addAfter(newControlPoints, i+1, controlPoints[this.state.selectedPointId])
            break;
          }
        }
      }

      this.setState({endPoints: newEndPoints, controlPoints: newControlPoints})

      // update any links that point to after this point
    }

  }

  copy() {
    this.setState({id:"-1"})
  }

  loadCurve(curve, id) {
    this.setState(curve);
    this.setState({id: id});
  }

  loadJson(bookjson) {
    let book = JSON.parse(bookjson)
    this.loadCurve({
      endPoints: book.endPoints,
      controlPoints: book.controlPoints,
      version: 1,
    })
  }

  handleMouseDownArray(pointId, num) {
    
    this.setState({ draggingPointId: pointId,
    draggingPointIdNum: num ,
    selectedPointId : pointId === "endPoints" ? num : null});
  }

  handleMouseUp() {
    this.setState({ draggingPointId: null });
  }

  handleMouseMove = ev => {
    if (this.props.passive){
      return;
    }

    // This event handles both mouseMove and touchMove.
    let x, y;
    if (ev.touches) {
      ev.preventDefault();
      const touch = ev.touches[0];
      [x, y] = [touch.clientX, touch.clientY];
    } else {
      [x, y] = [ev.clientX, ev.clientY];
    }

    const { viewBoxWidth, viewBoxHeight } = this.props;
    const { draggingPointId } = this.state;
    const { draggingPointIdNum } = this.state;

    // If we're not currently dragging a point, this is
    // a no-op. Nothing needs to be done.
    if (!draggingPointId) {
      return;
    }

    // During render, we capture a reference to the SVG
    // we're drawing, and store it on the instance with
    // `this.node`.
    // If we were to `console.log(this.node)`, we'd see a
    // reference to the underlying HTML element.
    // eg. `<svg viewBox="0 0 250 250"
    const svgRect = this.node.getBoundingClientRect();

    const svgX = x - svgRect.left;
    const svgY = y - svgRect.top;

    let viewBoxX = svgX * viewBoxWidth / svgRect.width;
    if (this.state.mirrormode === 1) {
      viewBoxX = viewBoxX < drawAreaX/2 ? drawAreaX/2 : viewBoxX;
    }

    // We do the same thing for the vertical direction:
    const viewBoxY = svgY * viewBoxHeight / svgRect.height;

    if (draggingPointId === 'controlPoints') {
      const controlPoints = this.state.controlPoints.slice()
      controlPoints[draggingPointIdNum] = { x: viewBoxX, y: viewBoxY }
      this.setState({
        controlPoints: controlPoints,
      });

    } else if (draggingPointId === 'endPoints') {
      const endPoints = this.state.endPoints.slice()
      endPoints[draggingPointIdNum] = { x: viewBoxX, y: viewBoxY, linked: endPoints[draggingPointIdNum].linked }
      this.setState({
        endPoints: endPoints,
      });

    } else {
      this.setState({
        [draggingPointId]: { x: viewBoxX, y: viewBoxY },
      });
    }
  }

  render() {
    const { viewBoxWidth, viewBoxHeight } = this.props;
    const {
      controlPoints,
	    endPoints
    } = this.state;

    // draw all endpoints with associated controlpoints to form a svg bezier
    // linked points will mirror their linked counterparts
    var instructions = ``;
    if (endPoints.length > 0) {      
      instructions = `
          M ${this.sampleCoords(endPoints[endPoints.length-1], endPoints).x},${this.sampleCoords(endPoints[endPoints.length-1], endPoints).y}
        `;
    }

    for (var i = 0; i < controlPoints.length; i++) {
      const epmodcoords = this.sampleCoords(endPoints[i], endPoints);
      const cpmodcoords = this.sampleCP(controlPoints, endPoints, i);
      instructions = instructions + `Q ${cpmodcoords.x},${cpmodcoords.y}
      ${epmodcoords.x},${epmodcoords.y} 
    `;
    }


    if (this.props.passive) {
      return (
        <svg
          ref={node => (this.node = node)}
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
          onClick={() => this.props.loadfunc({endPoints: this.state.endPoints,
            controlPoints: this.state.controlPoints}, this.state.id)}

          style={{
            overflow: 'visible',
            width: '100%',
            border: '0px solid',
          }}
        >
          <CircleEdge coordinates={{x: drawAreaX/2, y: drawAreaY/2}} r={230} />
          <Curve instructions={instructions} />
        </svg>
      );
    } else {
      // render function for the drawingn part
      return (
        <svg
          ref={node => (this.node = node)}
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
          onMouseMove={this.handleMouseMove}
          onTouchMove={this.handleMouseMove}
          onMouseUp={() => this.handleMouseUp()}
          onTouchEnd={() => this.handleMouseUp()}
          onMouseLeave={() => this.handleMouseUp()}
          style={{
            overflow: 'visible',
            width: '100%',
            border: '0px solid',
          }}
        >

          <CircleEdge coordinates={{x: drawAreaX/2, y: drawAreaY/2}} r={230} />
          <CircleWarning coordinates={{x: drawAreaX/2, y: drawAreaY/2}} r={200} />
          <GuideLine key="horizontalguide" from={{x: drawAreaX/2, y:0}} to={{x: drawAreaX/2, y:drawAreaY}} />
          <GuideLine key="verticallguide" from={{x: 0, y:drawAreaY/2}} to={{x: drawAreaX, y:drawAreaY/2}} />
          <GuideLineInv key="horizontalguidemark" from={{x: drawAreaX/2, y:0}} to={{x: drawAreaX/2, y:drawAreaY}} />
          <GuideLineInv key="verticallguidemark" from={{x: 0, y:drawAreaY/2}} to={{x: drawAreaX, y:drawAreaY/2}} />

          {controlPoints.map((value, index) => {
            return <ConnectingLine key={"cl_"+index} duallinked={this.cpLinked(endPoints, index) && this.cpLinked(endPoints, index+1)} from={this.sampleCoords(endPoints[(index-1+endPoints.length)%endPoints.length], endPoints)} to={this.sampleCP(controlPoints, endPoints, index)} />
          })}

          {endPoints.map((value, index) => {
            return <ConnectingLine key={"cl-"+index} duallinked={this.cpLinked(endPoints, index) && this.cpLinked(endPoints, index+1)} from={this.sampleCP(controlPoints, endPoints, index)} to={this.sampleCoords(value, endPoints)} />
          })}

          
          <Curve instructions={instructions} />

          {this.state.selectedPointId != null && <Coord coordinates={this.sampleCoords(endPoints[this.state.selectedPointId], endPoints)} linked={endPoints[this.state.selectedPointId].linked}
            />}
          

          {endPoints.map((value, index) => {
            return <LargeHandle selected={index === this.state.selectedPointId} key={"lh" + index} coordinates={this.sampleCoords(value, endPoints)} linked={value.linked}
            onMouseDown={() => value.linked === -1 ? this.handleMouseDownArray('endPoints', index): null} 
            onTouchDown={() => value.linked === -1 ? this.handleMouseDownArray('endPoints', index): null}
            />
          })}

          {controlPoints.map((value, index) => {
            return <SmallHandle  key={"sh" + index} coordinates={this.sampleCP(controlPoints, endPoints, index)} linked={this.cpLinked(endPoints, index) && this.cpLinked(endPoints, index+1 , )} onMouseDown={() => this.handleMouseDownArray('controlPoints', index)}/>
          })}
        </svg>
      );
        }
  }
}

// These helper stateless-functional-components allow us
// to reuse styles, and give each shape a meaningful name.

const ConnectingLine = ({ duallinked, from, to }) => (
  <line
    x1={from.x}
    y1={from.y}
    x2={to.x}
    y2={to.y}
    stroke="rgb(200, 200, 200)"
    strokeDasharray="5,5"
    strokeWidth={duallinked ? 0 : 2}
  />
);

const GuideLine = ({ from, to }) => (
  <line
    x1={from.x}
    y1={from.y}
    x2={to.x}
    y2={to.y}
    stroke="rgb(200, 200, 200)"
    strokeDasharray="49,1"
    strokeDashoffset="-0.5"
    strokeWidth={1}
  />
);
const GuideLineInv = ({ from, to }) => (
  <line
    x1={from.x}
    y1={from.y}
    x2={to.x}
    y2={to.y}
    stroke="rgb(0, 0, 0)"
    strokeDasharray="1,49"
    strokeDashoffset="0.5"
    strokeWidth={10}
  />
);

const Curve = ({ instructions }) => (
  <path
    d={instructions}
    fill="black"
    fill-opacity="50%"
    stroke="rgb(213, 0, 249)"
    strokeWidth={2}
  />
);

const CircleEdge = ({ coordinates, r }) => (
  <circle
  cx={coordinates.x}
  cy={coordinates.y}
  r={r}
  fill="none"
    stroke="rgb(0, 0, 0)"
    strokeWidth={2}
  />
);

const CircleWarning = ({ coordinates, r }) => (
  <circle
  cx={coordinates.x}
  cy={coordinates.y}
  r={r}
  fill="none"
    stroke="rgb(45, 45, 45)"
    strokeDasharray="5,5"
    strokeWidth={2}
  />
);

const LargeHandle = ({ selected, coordinates, onMouseDown, linked }) => (
  <ellipse
    cx={coordinates.x}
    cy={coordinates.y}
    rx={linked !== -1 ? 0 : 5}
    ry={linked !== -1 ? 0 : 5}
    fill={linked !== -1 ? "rgb(70, 70, 70)" :
              selected ? "rgb(0, 244, 137)" :
                         "rgb(244, 0, 137)"}
    onMouseDown={onMouseDown}
    style={{ cursor: '-webkit-grab' }}
  />
);

const Coord = ({ coordinates }) => (
  <text
    x={coordinates.x+8}
    y={coordinates.y+8}
    className="small">
    {Math.round((coordinates.x-drawAreaX/2)/10).toString()+","+Math.round((coordinates.y-drawAreaY/2)/10).toString()}
    </text>

);


const SmallHandle = ({ coordinates, onMouseDown, linked }) => (
  <ellipse
    cx={coordinates.x}
    cy={coordinates.y}
    rx={linked  ? 0 : 4}
    ry={linked  ? 0 : 4}
    fill="transparent"
    stroke={linked ? "rgb(70, 70, 70)": "rgb(244, 0, 137)"}
    strokeWidth={1}
    onMouseDown={onMouseDown}
    style={{ cursor: '-webkit-grab' }}
  />
);

var bezier0 = React.createRef();

//TODO: split
function sendCommand(command, bezier, id=-1, username="guest") {
  return fetch(command, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username.split('+')[0],
      password: username.split('+').length > 1 ? username.split('+')[1] : "",
      id: id,
      path: bezier
    })
  });
  
}

function onSave(username) {  
  const currentBezier0 = bezier0.current;

  return sendCommand(apiurl+"/api/save_path", currentBezier0.getBezierString(), currentBezier0.state.id, username).then(res => setId(res))
}

function onNew(mirrormode) {  
  const currentBezier0 = bezier0.current;

  currentBezier0.new(mirrormode)
}

function onCopy() {  
  const currentBezier0 = bezier0.current;

  currentBezier0.copy()
}

function onSplit() {  
  const currentBezier0 = bezier0.current;

  currentBezier0.split()
}

function onDelete(username) {  
  const currentBezier0 = bezier0.current;

  return sendCommand(apiurl+"/api/cleanup_path", currentBezier0.getBezierString(), currentBezier0.state.id, username)
}

function onSlider(val) {
  const currentBezier0 = bezier0.current;
  currentBezier0.setState({steps: val})
}

function onZoom(val) {
  const currentBezier0 = bezier0.current;
  currentBezier0.setState({zoom: val})
}

function onZoomDone(val) {
  const currentBezier0 = bezier0.current;
  currentBezier0.resampleCoords()
  currentBezier0.setState({zoom: 1.0})

}

function onGenerate(username) {  
  const currentBezier0 = bezier0.current;

  return sendCommand(apiurl+"/api/generate_model", currentBezier0.getBezierString(), currentBezier0.state.id, username).then(res => setId(res))
}

function setId(id) {
  id.json().then(data => {bezier0.current.setState({id: data.id})});
}

function loadCurve(curve, id) {
  const currentBezier0 = bezier0.current;

  currentBezier0.loadCurve(curve, id)
}

function App() {
  const [dbPaths, setDbPaths] = useState([]);

  // TODO: this is said to be unreacty
  function refreshList(username="guest") {
    fetch(apiurl+'/api/get_paths?' + new URLSearchParams({
      username: username.split('+')[0],
      password: username.split('+').length > 1 ? username.split('+')[1] : "",
  })).then(res => res.json()).then(data => {
      var rows = [];
      for (var i = 0; i < data.paths.length; i++) {
        rows.push(<div  key={"prev_"+i} className="App-latestentry"><div className="App-latestsvg"><Bezier steps={4} viewBoxWidth={drawAreaX} viewBoxHeight={drawAreaY} passive={true} path={data.paths[i]} id={data.id[i]} loadfunc={loadCurve}/></div>
        <Box sx={{ width: '50%', maxWidth: '100vh' }}>
      <nav aria-label="info on saved">
        <List dense>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary={data.modified[i].split(".")[0]} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <ListItemText primary={data.name[i]} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary={data.username[i]} />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
    </div>);
    }

    setDbPaths([]);
    setDbPaths(rows);
  });
  }

  useEffect(() => {
    refreshList();
  }, []);

  useEffect(() => {
    document.title = "Capdesigner"
    document.body.style.margin = 0;
  }, []);

  return (
    <div className="App">
	    <div className="App-main">
      <Bezier viewBoxWidth={drawAreaX} viewBoxHeight={drawAreaY} id={"-1"} passive={false} ref={bezier0}/>
      </div>
      <div className="Sidebar">
	    <div className="App-options">
        <Controlls copyCallback={onCopy} splitCallback={onSplit} newCallback={onNew} saveCallback={onSave} zoomCallback={onZoom} zoomDoneCallback={onZoomDone} sliderCallback={onSlider} genCallback={onGenerate} delCallback={onDelete} refreshCallback={refreshList}/>
        </div>
      <div className="App-latest">
        {dbPaths}
      </div>
      </div>
    </div>
  );
}

export default App;
